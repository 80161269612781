import React, { Component } from 'react';
import Scheduler, { Editing, Resource, View } from 'devextreme-react/scheduler';
import './AgendaPDS.css';
import notify from 'devextreme/ui/notify';
import CustomStore from 'devextreme/data/custom_store';
import moment from "moment";
import 'moment-timezone';
import { dataAppointmentStatusAdmin } from './Data/appointmentStatusAdmin.js';
import { dataAppointmentStatusDoctors } from './Data/appointmentStatusDoctors.js';
import Appointment from './Appointment.js';
import { withAuth0 } from '@auth0/auth0-react';
import { ConsoleView } from 'react-device-detect';

const idSucursalConstitucion = 'a593e064-8ff9-467d-b0f9-d779baa54eb6';
const idSucursalVinedos = '830857b1-6830-496e-a9f8-842032f3d258';
const idSucursalNavarro = '75a23d0e-7d8d-4114-8a2e-d7c1c89ec9eb';
const idSucursalRevolucion = '1e30df0f-40f3-427a-a132-4918d0554c92';

let dataAppointmentStatus = null;
let dataDoctors = null;
let dataBranchOffices = 'https://egl-pds-api.herokuapp.com/api/BranchOffice';
let dataServices = 'https://egl-pds-api.herokuapp.com/api/Service';


let dataBranchOfficeVinedos = 'https://egl-pds-api.herokuapp.com/api/BranchOffice/' + idSucursalVinedos;
let dataBranchOfficeNavarro = 'https://egl-pds-api.herokuapp.com/api/BranchOffice/' + idSucursalNavarro;
let dataBranchOfficeConstitucion = 'https://egl-pds-api.herokuapp.com/api/BranchOffice/' + idSucursalConstitucion;
let dataBranchOfficeRevolucion = 'https://egl-pds-api.herokuapp.com/api/BranchOffice/' + idSucursalRevolucion;
// cambiar a prod
let dataDoctorsVinedos = 'https://egl-pds-api.herokuapp.com/api/Doctor/GetDoctorsByBranchOffice/' + idSucursalVinedos;
let dataDoctorsNavarro = 'https://egl-pds-api.herokuapp.com/api/Doctor/GetDoctorsByBranchOffice/' + idSucursalNavarro;
let dataDoctorsConstitucion = 'https://egl-pds-api.herokuapp.com/api/Doctor/GetDoctorsByBranchOffice/' + idSucursalConstitucion;
let dataDoctorsRevolucion = 'https://egl-pds-api.herokuapp.com/api/Doctor/GetDoctorsByBranchOffice/' + idSucursalRevolucion;

const currentDate = new Date();
const dayOfWeekNames = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'];

// Grouping options

const branchOfficeGroups = ['branchOfficeId'];
const groups = ['branchOfficeId', 'doctorId'];

const serviceUrl = "https://egl-pds-api.herokuapp.com/api/Appointment";

export const appointmentStatusColor = [
    {
        id: 1,
        color: '#cb6bb2',
    }, {
        id: 2,
        color: '#56ca85',
    }, {
        id: 3,
        color: '#1e90ff',
    }, {
        id: 4,
        color: '#ff9747',
    },
];

let userId = null;
let branchId = null;
let isDoctorId = false;

const sucursalesPDS = new CustomStore({
    key: 'id',
    byKey: (key) => {
        return fetch(serviceUrl + "/" + key);
    },
    load: (loadOptions) => {
        getBranchOfficeId(userId)
        // myFunction(user)
        if (branchId != null) {
            return fetch(serviceUrl + '/' + branchId + '/' + isDoctorId)
                .then(handleErrors)
                .then(response => response.json())
                .catch(() => { throw 'Network error' })
        }
        else {
            return fetch(serviceUrl)
                .then(handleErrors)
                .then(response => response.json())
                .catch(() => { throw 'Network error' })
        }

    },
    insert: (values) => {
        let _startDate = moment.tz(values.startDate, "GMT");
        let _endDate = moment.tz(values.endDate, "GMT");

        values.startDate = _startDate;
        values.endDate = _endDate;
        return fetch(serviceUrl, {
            method: "POST",
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(values),
        })
            .then(handleErrors);
    },
    update: (key, values) => {
        let _startDate = moment.tz(values.startDate, "GMT");
        let _endDate = moment.tz(values.endDate, "GMT");

        values.startDate = _startDate;
        values.endDate = _endDate;

        const appointment = {


            doctorId: values.doctorId,
            branchOfficeId: values.branchOfficeId,
            description: values.description,
            appointmentStatus: values.appointmentStatus,
            serviceId: values.serviceId,
            startDate: values.startDate,
            endDate: values.endDate
        };

        return fetch(serviceUrl + "/" + key, {
            method: "PUT",
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(appointment),
        })
            .then(handleErrors)
    },
    remove: (key) => {
        return fetch(serviceUrl + "/" + key, {
            method: "Delete",
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8'
            }
        })
            .then(handleErrors);
    }
});

function getBranchOfficeId(userId) {
    branchId = null;

    if (userId === "google-oauth2|103805019871159289380" || userId === "auth0|64381c33983376898fccb5e3" || userId === "google-oauth2|102684668600914433947" || userId === "auth0|62fa678ae6af282704792b2d" || userId === "auth0|6384cc7cf4edfed30f808906" || userId === "auth0|654ded4cbf7d2183c3201367") {
        branchId = idSucursalNavarro;
        dataBranchOffices = dataBranchOfficeNavarro;
    }

    // if (userId === "auth0|6384cc7cf4edfed30f808906") {
    //     branchId = 'd965a95b-17dc-4719-94ab-27b54a7c7671';
    //     isDoctorId = true;

    // }

    // Sucursal Viñedos
    if (userId === "auth0|63096b79cb2a7d92d814767e") {
        branchId = idSucursalVinedos;
        dataBranchOffices = dataBranchOfficeVinedos;
    }

    // Sucursal Navarro
    if (userId === "auth0|63768e35802804f3214b903b") {
        branchId = idSucursalNavarro;
        dataBranchOffices = dataBranchOfficeNavarro;
    }

    // Sucursal Constitución
    if (userId === "auth0|63768f4c911bc568294085a4" || userId === "auth0|6384cc94c0cb508c49df7773") {
        branchId = idSucursalConstitucion;
        dataBranchOffices = dataBranchOfficeConstitucion;
    }

    // Sucursal Revolución
    if (userId === "auth0|63768fa5ea0961b9a9b77d89") {
        branchId = idSucursalRevolucion;
        dataBranchOffices = dataBranchOfficeRevolucion;
    }
}

function getBranchOffices(userId) {
    if (userId === "google-oauth2|103805019871159289380" || userId === "auth0|64381c33983376898fccb5e3" || userId === "google-oauth2|102684668600914433947" || userId === "auth0|62fa678ae6af282704792b2d" || userId === "auth0|6384cc7cf4edfed30f808906" || userId === "auth0|654ded4cbf7d2183c3201367") {
        dataBranchOffices = dataBranchOfficeNavarro;
    }

    // Sucursal Viñedos
    if (userId === "auth0|63096b79cb2a7d92d814767e") {
        dataBranchOffices = dataBranchOfficeVinedos;
    }

    // Sucursal Navarro
    if (userId === "auth0|63768e35802804f3214b903b") {
        dataBranchOffices = dataBranchOfficeNavarro;
    }

    // Sucursal Constitución
    if (userId === "auth0|63768f4c911bc568294085a4" || userId === "auth0|6384cc94c0cb508c49df7773") {
        dataBranchOffices = dataBranchOfficeConstitucion;
    }

    // Sucursal Revolución
    if (userId === "auth0|63768fa5ea0961b9a9b77d89") {
        dataBranchOffices = dataBranchOfficeRevolucion;
    }

    return dataBranchOffices;
}

function getDoctors(userId) {
    // Admin
    if (userId === "google-oauth2|103805019871159289380" || userId === "auth0|64381c33983376898fccb5e3" || userId === "google-oauth2|102684668600914433947" || userId === "auth0|62fa678ae6af282704792b2d" || userId === "auth0|6384cc7cf4edfed30f808906" || userId === "auth0|654ded4cbf7d2183c3201367") {
        dataDoctors = dataDoctorsNavarro;
    }

    // // Dr. Victoria 
    // if (userId === "auth0|6384cc7cf4edfed30f808906") {
    //     dataDoctors = 'https://egl-pds-api.herokuapp.com/api/Doctor/d965a95b-17dc-4719-94ab-27b54a7c7671';
    // }
    // Sucursal Viñedos
    if (userId === "auth0|63096b79cb2a7d92d814767e") {
        dataDoctors = dataDoctorsVinedos;
    }

    // Sucursal Navarro
    if (userId === "auth0|63768e35802804f3214b903b") {
        dataDoctors = dataDoctorsNavarro;
    }

    // Sucursal Constitución
    if (userId === "auth0|63768f4c911bc568294085a4" || userId === "auth0|6384cc94c0cb508c49df7773") {
        dataDoctors = dataDoctorsConstitucion;
    }

    // Sucursal Revolución
    if (userId === "auth0|63768fa5ea0961b9a9b77d89") {
        dataDoctors = dataDoctorsRevolucion;
    }

    return dataDoctors;
}

function getAppointmentStatus(userId) {
    if (userId === "google-oauth2|103805019871159289380" || userId === "auth0|64381c33983376898fccb5e3" || userId === "google-oauth2|102684668600914433947" || userId === "auth0|62fa678ae6af282704792b2d" || userId === "auth0|6384cc7cf4edfed30f808906" || userId === "auth0|654ded4cbf7d2183c3201367") {
        dataAppointmentStatus = dataAppointmentStatusAdmin;
    } else {
        dataAppointmentStatus = dataAppointmentStatusDoctors;
    }

    return dataAppointmentStatus;
}

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

class AgendaPDSNavarro extends Component {

    render() {

        const { user } = this.props.auth0;
        userId = user.sub;

        if (userId === "google-oauth2|103805019871159289380" || userId === "auth0|64381c33983376898fccb5e3" || userId === "google-oauth2|102684668600914433947" || userId === "auth0|62fa678ae6af282704792b2d" || userId === "auth0|6384cc7cf4edfed30f808906") {
            return (

                < Scheduler
                    timeZone="America/Mexico_City"
                    adaptivityEnabled={true}
                    dataSource={sucursalesPDS}
                    defaultCurrentView="week"
                    showAllDayPanel={false}
                    defaultCurrentDate={currentDate}
                    height={900}
                    startDayHour={7}
                    endDayHour={22}
                    showCurrentTimeIndicator={false}
                    shadeUntilCurrentTime={false}
                    editing={true}
                    allowAdding={true}
                    allowDeleting={false}
                    onAppointmentFormOpening={onAppointmentFormOpening}
                    onAppointmentAdding={onAppointmentAdding}
                    cellDuration={30}
                    appointmentComponent={Appointment}
                    onAppointmentAdded={onAppointmentAdded}
                    onAppointmentUpdating={onAppointmentUpdating}
                    // onAppointmentDeleting={onAppointmentDeleting}
                    allowTimeZoneEditing={true}
                >

                    <View type="day" name="Día" groups={groups} />
                    <View type="week" name="Semana" groups={branchOfficeGroups} dateCellRender={renderDateCell} />
                    <View type="month" name="Mes" groups={branchOfficeGroups} dateCellRender={renderDateCell} />

                    {/* <Resource dataSource={dataBranchOffices} fieldExpr="branchOfficeId" label="Sucursal" allowMultiple={false} /> */}
                    <Resource dataSource={getBranchOffices(userId)} fieldExpr="branchOfficeId" label="Sucursal" allowMultiple={false} />
                    <Resource dataSource={getDoctors(userId)} fieldExpr="doctorId" label="Podólogo" allowMultiple={false} />
                    <Resource dataSource={getAppointmentStatus(userId)} fieldExpr="appointmentStatus" label="dataAppointmentStatus" allowMultiple={false} useColorAsDefault={true} />
                </Scheduler >
            );
        }
        else if (userId === "auth0|654ded4cbf7d2183c3201367") {

            return (

                < Scheduler
                    timeZone="America/Mexico_City"
                    adaptivityEnabled={true}
                    dataSource={sucursalesPDS}
                    defaultCurrentView="week"
                    showAllDayPanel={false}
                    defaultCurrentDate={currentDate}
                    height={900}
                    startDayHour={7}
                    endDayHour={22}
                    showCurrentTimeIndicator={false}
                    shadeUntilCurrentTime={true}
                    editing={false}
                    allowAdding={false}
                    allowDeleting={false}
                    onAppointmentFormOpening={onAppointmentFormOpening}
                    onAppointmentAdding={onAppointmentAdding}
                    cellDuration={30}
                    appointmentComponent={Appointment}
                    onAppointmentAdded={onAppointmentAdded}
                    onAppointmentUpdating={onAppointmentUpdating}
                    // onAppointmentDeleting={onAppointmentDeleting}
                    allowTimeZoneEditing={true}
                >
                    <View type="day" name="Día" groups={groups} />
                    <View type="week" name="Semana" groups={branchOfficeGroups} dateCellRender={renderDateCell} />
                    <View type="month" name="Mes" groups={branchOfficeGroups} dateCellRender={renderDateCell} />

                    <Resource dataSource={getBranchOffices(userId)} fieldExpr="branchOfficeId" label="Sucursal" allowMultiple={false} />
                    <Resource dataSource={getDoctors(userId)} fieldExpr="doctorId" label="Podólogo" allowMultiple={false} />
                    <Resource dataSource={getAppointmentStatus(userId)} fieldExpr="appointmentStatus" label="dataAppointmentStatus" allowMultiple={false} useColorAsDefault={true} />
                </Scheduler >
            );

        }
        else if (userId === "auth0|63768e35802804f3214b903b") {
            return (

                < Scheduler
                    timeZone="America/Mexico_City"
                    adaptivityEnabled={true}
                    dataSource={sucursalesPDS}
                    defaultCurrentView="week"
                    showAllDayPanel={false}
                    defaultCurrentDate={currentDate}
                    height={900}
                    startDayHour={7}
                    endDayHour={22}
                    showCurrentTimeIndicator={false}
                    shadeUntilCurrentTime={true}
                    editing={true}
                    allowAdding={true}
                    allowDeleting={false}
                    onAppointmentFormOpening={onAppointmentFormOpening}
                    onAppointmentAdding={onAppointmentAdding}
                    cellDuration={30}
                    appointmentComponent={Appointment}
                    onAppointmentAdded={onAppointmentAdded}
                    onAppointmentUpdating={onAppointmentUpdating}
                    // onAppointmentDeleting={onAppointmentDeleting}
                    allowTimeZoneEditing={true}
                >
                    <View type="day" name="Día" groups={groups} />
                    <View type="week" name="Semana" groups={branchOfficeGroups} dateCellRender={renderDateCell} />
                    <View type="month" name="Mes" groups={branchOfficeGroups} dateCellRender={renderDateCell} />
                    <Resource dataSource={getBranchOffices(userId)} fieldExpr="branchOfficeId" label="Sucursal" allowMultiple={false} />
                    <Resource dataSource={getDoctors(userId)} fieldExpr="doctorId" label="Podólogo" allowMultiple={false} />
                    <Resource dataSource={getAppointmentStatus(userId)} fieldExpr="appointmentStatus" label="dataAppointmentStatus" allowMultiple={false} useColorAsDefault={true} />
                </Scheduler >
            );
        }
        else {
            return <h1> Acceso denegado</h1>
        }
    }
}

function showToast(event, value, type) {
    notify(`${event} "${value}"`, type, 2000);
}



const onAppointmentDeleting = (e) => {
    sucursalesPDS.remove(e.appointmentData.id);
    showToast('Se borro exitosamenta la cite de', e.appointmentData.text, 'error');
}

const onAppointmentAdded = (e) => {

    showToast('onAppointmentAdded', 'success');
    // Handler of the "appointmentAdded" event
}

const onAppointmentAdding = (e) => {
    console.log(e.appointmentData);
    let _startDate = moment(e.appointmentData.startDate);
    let _initialDate = moment(_startDate.format('YYYY-MM-DD') + ' ' + "07:00:00");
    let _finalDate = moment(_startDate.format('YYYY-MM-DD') + ' ' + "20:00:00");

    let _isValidAppointment = _startDate.isBetween(_initialDate, _finalDate, 'hours', '[]')

    if (!_isValidAppointment) {
        e.cancel = true;
        notifyDisableDate();
    }
}

const onAppointmentUpdating = (e) => {

    let _startDate = moment(e.newData.startDate);
    let _initialDate = moment(_startDate.format('YYYY-MM-DD') + ' ' + "07:00:00");
    let _finalDate = moment(_startDate.format('YYYY-MM-DD') + ' ' + "20:00:00");

    let _isValidAppointment = _startDate.isBetween(_initialDate, _finalDate, 'hours', '[]')

    if (!_isValidAppointment) {
        e.cancel = true;
        notifyDisableDate();
    } else {
        sucursalesPDS.update(e.oldData.id, e.newData);
        showToast('Se actualizo exitosamente la cita de', e.oldData.text, 'info');
    }
}

const notifyDisableDate = () => {
    showToast('Horario no permitido para realizar una cita', '', 'warning');
};


// const phoneEditorOptions = {
//     mask: '(999)-999-9999',
//     maskRules: {
//         X: /[02-9]/,
//     },
//     maskInvalidMessage: 'The phone must have a correct USA phone format',
// };

function repeatAppointment(e) {
    e.component.hideAppointmentPopup();

    let copyAppointment = {
        branchOfficeId: e.appointmentData.branchOfficeId,
        description: e.appointmentData.description,
        appointmentStatus: 1,
        startDate: e.appointmentData.startDate,
        endDate: e.appointmentData.endDate,
        serviceId: e.appointmentData.serviceId,
        text: e.appointmentData.text,
        phone: e.appointmentData.phone,
        email: e.appointmentData.email
    };

    sessionStorage.setItem('copyAppointmentData', JSON.stringify(copyAppointment));

    e.component.showAppointmentPopup();
}

function onAppointmentFormOpening(e) {
    const { form } = e;
    let { startDate } = e.appointmentData;

    if (e.appointmentData.id != null) {

        let toolbarItems = e.popup.option("toolbarItems");
        const copiarButton = {
            widget: "dxButton",
            location: "before",
            toolbar: "bottom",
            options: {
                text: "Repetir cita",
                onClick(args) {
                    repeatAppointment(e);
                }
            }
        };
        toolbarItems.push(copiarButton);

        toolbarItems.forEach(item => {
            if (item.options && item.options.text === "Done") {
                item.options.text = "Guardar";
            }
            if (item.options && item.options.text === "Cancel") {
                item.options.text = "Cerrar";
            }
        })
        e.popup.option("toolbarItems", toolbarItems);

        form.option('items', [
            {
                visible: false,
                name: 'userId',
                colSpan: 2,
                editorType: 'dxTextBox',
                dataField: 'userId',
                editorOptions: {
                    value: 'google-oauth2|102684668600914433947'

                }

            },
            {
                name: 'name',
                colSpan: 2,
                label: {
                    text: 'Paciente',
                },
                editorType: 'dxTextBox',
                dataField: 'text',
                editorOptions: {
                    readOnly: true
                }

            },
            {
                name: 'phone',
                colSpan: 2,
                label: { text: "Teléfono" },
                editorType: "dxTextBox",
                dataField: "phone",
                editorOptions: {
                    readOnly: true
                }
            },
            {
                name: 'email',
                colSpan: 2,
                label: { text: "Correo Electrónico" },
                editorType: "dxTextBox",
                dataField: "email",
                editorOptions: {
                    readOnly: true
                }
            },
            {
                label: {
                    text: 'Fecha de inicio',
                },
                name: 'startDate',
                dataField: 'startDate',
                editorType: 'dxDateBox',
                editorOptions: {
                    width: '100%',
                    type: "datetime",
                    onValueChanged(args) {
                        startDate = moment.tz(args.value, "America/Mexico_City");

                        if (startDate != null || startDate != undefined) {
                            form.updateData('endDate', startDate.add(1, 'hours'));
                        }
                    }
                },
                validationRules: [{
                    type: "required",
                    message: "Este campo es requerido"
                }]
            },
            {
                label: {
                    text: 'Fecha de final',
                },
                name: 'endDate',
                dataField: 'endDate',
                editorType: 'dxDateBox',
                editorOptions: {
                    width: '100%',
                    type: 'datetime',
                    readOnly: false,
                }
            },
            {
                name: 'description',
                colSpan: 2,
                label: {
                    text: 'Comentario',
                },
                editorType: 'dxTextArea',
                dataField: 'description',
                editorOptions: {
                    readOnly: true
                }
            },
            {
                name: 'serviceId',
                label: {
                    text: 'Servicio',
                },
                editorType: 'dxSelectBox',
                dataField: 'serviceId',
                editorOptions: {
                    dataSource: dataServices,
                    displayExpr: 'text',
                    valueExpr: 'id',
                    readOnly: true
                }
            },

            {
                name: 'branchOfficeId',
                label: {
                    text: 'Sucursal',
                },
                editorType: 'dxSelectBox',
                dataField: 'branchOfficeId',
                editorOptions: {
                    dataSource: dataBranchOffices,
                    displayExpr: 'text',
                    valueExpr: 'id',
                    readOnly: true
                }

            },
            {
                name: 'doctorId',
                label: {
                    text: 'Podólogo',
                },
                editorType: 'dxSelectBox',
                dataField: 'doctorId',
                editorOptions: {
                    dataSource: dataDoctors,
                    displayExpr: 'text',
                    valueExpr: 'id',
                    readOnly: false
                }
            },
            {
                label: {
                    text: 'Status',
                },
                name: 'appointmentStatus',
                editorType: 'dxSelectBox',
                dataField: 'appointmentStatus',
                editorOptions: {
                    dataSource: dataAppointmentStatus,
                    displayExpr: 'text',
                    valueExpr: 'id'
                },
            }

        ]);
    }
    else {

        const serializedCopyAppointmentData = sessionStorage.getItem('copyAppointmentData');

        if (serializedCopyAppointmentData === null) {
            form.option('items', [
                {
                    visible: false,
                    name: 'userId',
                    colSpan: 2,
                    editorType: 'dxTextBox',
                    dataField: 'userId',
                    editorOptions: {
                        value: 'google-oauth2|102684668600914433947'

                    }

                },
                {
                    name: 'name',
                    colSpan: 2,
                    label: {
                        text: 'Paciente',
                    },
                    editorType: 'dxTextBox',
                    dataField: 'text',
                    validationRules: [{
                        type: "required",
                        message: "Este campo es requerido"
                    }]

                },
                {
                    name: 'phone',
                    colSpan: 2,
                    label: { text: "Teléfono" },
                    editorType: "dxTextBox",
                    dataField: "phone",
                    validationRules: [{
                        type: "required",
                        message: "Este campo es requerido"
                    }]
                },
                {
                    name: 'email',
                    colSpan: 2,
                    label: { text: "Correo Electrónico" },
                    editorType: "dxTextBox",
                    dataField: "email"
                },
                {
                    label: {
                        text: 'Fecha de inicio',
                    },
                    name: 'startDate',
                    dataField: 'startDate',
                    editorType: 'dxDateBox',
                    editorOptions: {
                        width: '100%',
                        type: "datetime",
                        onValueChanged(args) {
                            startDate = moment.tz(args.value, "America/Mexico_City");

                            if (startDate != null || startDate != undefined) {
                                form.updateData('endDate', startDate.add(1, 'hours'));
                            }
                        }
                    },
                    validationRules: [{
                        type: "required",
                        message: "Este campo es requerido"
                    }]
                },
                {
                    label: {
                        text: 'Fecha de final',
                    },
                    name: 'endDate',
                    dataField: 'endDate',
                    editorType: 'dxDateBox',
                    editorOptions: {
                        width: '100%',
                        type: 'datetime',
                        readOnly: false,
                    }
                },
                {
                    name: 'description',
                    colSpan: 2,
                    label: {
                        text: 'Comentario',
                    },
                    editorType: 'dxTextArea',
                    dataField: 'description',
                },
                {
                    label: {
                        text: 'Servicio',
                    },
                    name: 'serviceId',
                    editorType: 'dxSelectBox',
                    dataField: 'serviceId',
                    editorOptions: {
                        dataSource: dataServices,
                        displayExpr: 'text',
                        valueExpr: 'id'
                    },
                    validationRules: [{
                        type: "required",
                        message: "Este campo es requerido"
                    }]
                },

                {
                    label: {
                        text: 'Sucursal',
                    },
                    name: 'branchOfficeId',
                    editorType: 'dxSelectBox',
                    dataField: 'branchOfficeId',
                    editorOptions: {
                        dataSource: dataBranchOffices,
                        displayExpr: 'text',
                        valueExpr: 'id'
                    },
                    validationRules: [{
                        type: "required",
                        message: "Este campo es requerido"
                    }]
                },
                {
                    name: 'doctorId',
                    label: {
                        text: 'Podólogo',
                    },
                    editorType: 'dxSelectBox',
                    dataField: 'doctorId',
                    editorOptions: {
                        dataSource: dataDoctors,
                        displayExpr: 'text',
                        valueExpr: 'id'
                    },
                    validationRules: [{
                        type: "required",
                        message: "Este campo es requerido"
                    }]
                },
                {
                    visible: false,
                    label: {
                        text: 'Status',
                    },
                    name: 'appointmentStatus',
                    editorType: 'dxSelectBox',
                    dataField: 'appointmentStatus',
                    editorOptions: {
                        dataSource: dataAppointmentStatus,
                        displayExpr: 'text',
                        valueExpr: 'id',
                        disabled: 'disabled',
                        value: 1
                    },
                }
            ]);
        }
        else {
            const copyAppointment = JSON.parse(serializedCopyAppointmentData);
            form.option('items', [
                {
                    visible: false,
                    name: 'userId',
                    colSpan: 2,
                    editorType: 'dxTextBox',
                    dataField: 'userId',
                    editorOptions: {
                        value: 'google-oauth2|102684668600914433947'

                    }

                },
                {
                    name: 'name',
                    colSpan: 2,
                    label: {
                        text: 'Paciente',
                    },
                    editorType: 'dxTextBox',
                    dataField: 'text',
                    editorOptions: {
                        value: copyAppointment.text

                    },
                    validationRules: [{
                        type: "required",
                        message: "Este campo es requerido"
                    }]

                },
                {
                    name: 'phone',
                    colSpan: 2,
                    label: { text: "Teléfono" },
                    editorType: "dxTextBox",
                    dataField: "phone",
                    editorOptions: {
                        value: copyAppointment.phone

                    },
                    validationRules: [{
                        type: "required",
                        message: "Este campo es requerido"
                    }]
                },
                {
                    name: 'email',
                    colSpan: 2,
                    label: { text: "Correo Electrónico" },
                    editorType: "dxTextBox",
                    dataField: "email",
                    editorOptions: {
                        value: copyAppointment.email

                    }
                },
                {
                    label: {
                        text: 'Fecha de inicio',
                    },
                    name: 'startDate',
                    dataField: 'startDate',
                    editorType: 'dxDateBox',
                    editorOptions: {
                        value: copyAppointment.startDate,
                        width: '100%',
                        type: "datetime",
                        onValueChanged(args) {
                            startDate = moment.tz(args.value, "America/Mexico_City");

                            if (startDate != null || startDate != undefined) {
                                form.updateData('endDate', startDate.add(1, 'hours'));
                            }
                        }
                    },
                    validationRules: [{
                        type: "required",
                        message: "Este campo es requerido"
                    }]
                },
                {
                    label: {
                        text: 'Fecha de final',
                    },
                    name: 'endDate',
                    dataField: 'endDate',
                    editorType: 'dxDateBox',
                    editorOptions: {
                        value: copyAppointment.endDate,
                        width: '100%',
                        type: 'datetime',
                        readOnly: false,
                    }
                },
                {
                    name: 'description',
                    colSpan: 2,
                    label: {
                        text: 'Comentario',
                    },
                    editorType: 'dxTextArea',
                    dataField: 'description',
                    editorOptions: {
                        value: copyAppointment.description

                    }
                },
                {
                    label: {
                        text: 'Servicio',
                    },
                    name: 'serviceId',
                    editorType: 'dxSelectBox',
                    dataField: 'serviceId',
                    editorOptions: {
                        dataSource: dataServices,
                        displayExpr: 'text',
                        valueExpr: 'id',
                        value: copyAppointment.serviceId
                    },
                    validationRules: [{
                        type: "required",
                        message: "Este campo es requerido"
                    }]
                },

                {
                    label: {
                        text: 'Sucursal',
                    },
                    name: 'branchOfficeId',
                    editorType: 'dxSelectBox',
                    dataField: 'branchOfficeId',
                    editorOptions: {
                        dataSource: dataBranchOffices,
                        displayExpr: 'text',
                        valueExpr: 'id',
                        value: copyAppointment.branchOfficeId
                    },
                    validationRules: [{
                        type: "required",
                        message: "Este campo es requerido"
                    }]
                },
                {
                    name: 'doctorId',
                    label: {
                        text: 'Podólogo',
                    },
                    editorType: 'dxSelectBox',
                    dataField: 'doctorId',
                    editorOptions: {
                        dataSource: dataDoctors,
                        displayExpr: 'text',
                        valueExpr: 'id'
                    },
                    validationRules: [{
                        type: "required",
                        message: "Este campo es requerido"
                    }]
                },
                {
                    visible: false,
                    label: {
                        text: 'Status',
                    },
                    name: 'appointmentStatus',
                    editorType: 'dxSelectBox',
                    dataField: 'appointmentStatus',
                    editorOptions: {
                        dataSource: dataAppointmentStatus,
                        displayExpr: 'text',
                        valueExpr: 'id',
                        disabled: 'disabled',
                        value: 1
                    },
                }
            ]);

            sessionStorage.removeItem('copyAppointmentData');
            sessionStorage.clear();
        }

    }
}


function renderDateCell(cellData) {
    return (
        <React.Fragment>
            <div className="name">{dayOfWeekNames[cellData.date.getDay()]}</div>
            <div className="number">{cellData.date.getDate()}</div>
        </React.Fragment>
    );
}

export default withAuth0(AgendaPDSNavarro)
