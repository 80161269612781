import React from 'react';
import Query from 'devextreme/data/query';
import './Appointment-Preview.css';
import moment from "moment";
import 'moment-timezone';

const appointmentsData = "https://egl-pds-api.herokuapp.com/api/Appointment";

function getAppointmentById(id) {
    return Query(appointmentsData).filter(['id', "=", id]).toArray()[0];
}

export default function Appointment(model) {
    const { targetedAppointmentData } = model.data;

    // const appointmentData = getAppointmentById(targetedAppointmentData.id) || {};s

    return (
        <div className="appointment-preview">
            <div className="title-preview"> {targetedAppointmentData.text}</div>
            <div className="date-preview">
                {moment(targetedAppointmentData.startDate).tz('America/Mexico_City').format("hh:mm:ss A")}
                {' - '}
                {moment(targetedAppointmentData.endDate).tz('America/Mexico_City').format("hh:mm:ss A")}
            </div>
            <div className="phone-preview">
                Tel:{targetedAppointmentData.phone}
            </div>
            <div className="description-preview">
                {targetedAppointmentData.description}
            </div>

        </div>
    );
}
