import React from 'react';
import notify from 'devextreme/ui/notify';
import CustomStore from 'devextreme/data/custom_store';
import moment from "moment";
import 'moment-timezone';
import './AppointmentList.css';
// import { dataAppointmentStatus } from './Data/appointmentStatus.js';
import 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import {
    Column,
    DataGrid,
    FilterRow,
    HeaderFilter,
    GroupPanel,
    Scrolling,
    Editing,
    Grouping,
    Lookup,
    MasterDetail,
    Summary,
    RangeRule,
    RequiredRule,
    StringLengthRule,
    GroupItem,
    TotalItem,
    ValueFormat,
} from 'devextreme-react/data-grid';
import DateBox from 'devextreme-react/date-box';

import { withAuth0 } from '@auth0/auth0-react';

const serviceUrl = "https://egl-pds-api.herokuapp.com/api/Appointment/GetAllAppointments";

const appointmentList = new DataSource({
    load: (loadOptions) => {
        return fetch(serviceUrl)
            .then(handleErrors)
            .then(response => response.json())
            .catch(() => { throw 'Network error' });
    },
    byKey: (key) => {
        // Retrieving a data object by key
    },
    onLoadError: (error) => {
        console.log(error.message);
    }
});

function handleErrors(response) {
    if (!response.ok) {
        console.log("error");
        throw Error(response.statusText);
    } else {
        console.log("success");
    }
    return response;
};

class AppointmentList extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <DataGrid
                    dataSource={appointmentList}
                    height={600}
                    showColumnLines={true}
                    showRowLines={true}
                    showBorders={true}
                    rowAlternationEnabled={true}
                    remoteOperations={false}
                >
                    <FilterRow visible={true} />
                    <HeaderFilter visible={false} />
                    <GroupPanel visible={false} />
                    <Scrolling mode="virtual" />
                    <Editing
                        mode="row"
                    />
                    <Grouping autoExpandAll={false} />

                    <Column dataField="text" className="clientName" caption="Cliente">

                    </Column>

                    <Column dataField="startDate" dataType="date" caption="Fecha">
                    </Column>

                    <Column
                        dataField="branchOffice"
                        caption="Sucursal"
                    >
                    </Column>
                    <Column
                        dataField="doctor"
                        caption="Podólogo"
                    >
                    </Column>
                    <Column dataField="appointmentStatusName" caption="Estatus">
                    </Column>
                    <GroupItem summaryType="count" />
                </DataGrid>
            </div>
        );

    }
}

function showToast(event, value, type) {
    notify(`${event} "${value}" task`, type, 2000);
    // showToast('Se actualizo exitosamente la cita de', e.oldData.text, 'info');
}


export default withAuth0(AppointmentList);
