import React from 'react';
import axios from 'axios';
//import MenuItem from '@mui/material/MenuItem';
//import { Select } from '@mui/material';

export default class DoctorList extends React.Component {
    state = {
        doctor: []
    };

    componentDidMount() {
        axios.get(`https://egl-pds-api.herokuapp.com/api/Doctor`).then((res) => {
            const doctor = res.data;
            this.setState({ doctor });
        });
    }

    render() {
        return (

            <>
                <select>
                    {this.state.doctor.map((doctor) => (
                        <option value={doctor.id} key={doctor.id}>{doctor.text}</option>
                    ))}
                </select>
            </>
        );
    }
}
