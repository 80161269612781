import React from 'react';
import notify from 'devextreme/ui/notify';
import CustomStore from 'devextreme/data/custom_store';
import moment from "moment";
import 'moment-timezone';
import './DoctorList.css';
// import { dataAppointmentStatus } from './Data/appointmentStatus.js';
import 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import { TagBox } from 'devextreme-react/tag-box';
import {
    Column,
    DataGrid,
    FilterRow,
    HeaderFilter,
    GroupPanel,
    Scrolling,
    Paging,
    Editing,
    Grouping,
    Pager,
    ButtonItem,
    Popup,
    Lookup,
    MasterDetail,
    Summary,
    RangeRule,
    RequiredRule,
    StringLengthRule,
    GroupItem,
    Form,
    TotalItem,
    ValueFormat,
} from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import { Item } from 'devextreme-react/form';
import DateBox from 'devextreme-react/date-box';

import { withAuth0 } from '@auth0/auth0-react';
import { object } from 'prop-types';

// const serviceUrl = "https://egl-pds-api.herokuapp.com/api/Doctor/GetAllDoctors";
// const serviceUrl = "https://localhost:7103/api/Doctor"
const serviceUrl = "https://egl-pds-api.herokuapp.com/api/Doctor";
// const dataBranchOfficesUrl = "https://localhost:7103/api/BranchOffice";
const dataBranchOfficesUrl = "https://egl-pds-api.herokuapp.com/api/BranchOffice";

const displayModes = [{ text: 'Display Mode \'full\'', value: 'full' }, { text: 'Display Mode \'compact\'', value: 'compact' }];
const allowedPageSizes = [10, 20, 'all'];
let defaultValues = {
    grouped: []
};

let _tagBox;
let userId = null;
// const serviceUrl = "https://egl-pds-api.herokuapp.com/api/Doctor";
const productLabel = { 'aria-label': 'Product' };

const doctorsPDS = new DataSource({
    key: 'id',
    load: (loadOptions) => {
        return fetch(serviceUrl + '/' + 'GetAllDoctors')
            .then(handleErrors)
            .then(response => response.json())
            .catch(() => { throw 'Network error' });
    },
    byKey: (key) => {
        return fetch(serviceUrl + "/" + key);
    },
    onLoadError: (error) => {
        console.log(error.message);
    },
    insert: (values) => {
        values.userId = 'b1feaebc-2ed6-47e1-8d4f-daa58e09e050';
        values.branchOfficeId = _tagBox.component.option("value");
        return fetch(serviceUrl, {
            method: "POST",
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(values),
        })
            .then(handleErrors);
    },
    update: (key, values) => {
        values.userId = 'b1feaebc-2ed6-47e1-8d4f-daa58e09e050';
        values.branchOfficeId = _tagBox.component.option("value");

        return fetch(serviceUrl + "/" + key, {
            method: "PUT",
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(values),
        })
            .then(handleErrors)
    },
    remove: (key) => {
        let adminId = 'b1feaebc-2ed6-47e1-8d4f-daa58e09e050';
        let id = key;

        return fetch(serviceUrl + "/" + adminId + "," + id, {
            method: "Delete",
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8'
            }
        })
            .then(handleErrors);
    }
});

const dataBranchOffices = new DataSource({
    key: "id",
    load: (loadOptions) => {
        return fetch(dataBranchOfficesUrl)
            .then(handleErrors)
            .then(response => response.json())
            .catch(() => { throw 'Network error' });
    },
});

let podologoInfo = Object;

let fromPodologo = { Form };

function handleErrors(response) {
    if (!response.ok) {
        console.log("error");
        throw Error(response.statusText);
    } else {
        console.log("success");
    }
    return response;
};

class DoctorList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            displayMode: 'full',
            showPageSizeSelector: true,
            showInfo: true,
            showNavButtons: true,
            isEmailVisible: true
        };
    }

    hideEmails() {
        this.setState({
            isEmailVisible: false
        });
    }

    render() {
        const { user } = this.props.auth0;
        userId = user.sub;
        if (userId === "google-oauth2|103805019871159289380" || userId === "auth0|64381c33983376898fccb5e3" || userId === "google-oauth2|102684668600914433947" || userId === "auth0|62fa678ae6af282704792b2d" || userId === "auth0|6384cc7cf4edfed30f808906") {
            return (
                <div>
                    <h1>Podólogos</h1>
                    <hr></hr>
                    <DataGrid
                        dataSource={doctorsPDS}
                        height={800}
                        showColumnLines={true}
                        showRowLines={true}
                        showBorders={true}
                        rowAlternationEnabled={true}
                        remoteOperations={false}
                        onEditingStart={onPopupFormOpening}
                        onSaving={savingMethod}
                        focusedRowEnabled={true}
                    >

                        <Scrolling rowRenderingMode='virtual'></Scrolling>
                        <Paging defaultPageSize={20} />
                        <Pager
                            visible={true}
                            allowedPageSizes={allowedPageSizes}
                            displayMode={this.state.displayMode}
                            showPageSizeSelector={this.state.showPageSizeSelector}
                            showInfo={this.state.showInfo}
                            showNavigationButtons={this.state.showNavButtons} />

                        <FilterRow visible={true} />
                        <HeaderFilter visible={false} />
                        <GroupPanel visible={false} />
                        <Editing
                            mode="popup"
                            allowUpdating={true}
                            allowAdding={true}
                            allowDeleting={true}
                            useIcons={true}
                        >
                            <Popup title="Podólogo Info" id="popUpPodologos" className="popUpPodologos" showTitle={true} onHiding={cleanTagbox} />
                            <Form id="podologoInfo" showColonAfterLabel={false} labelLocation="top">
                                <Item itemType="group" colCount={2} colSpan={2}>
                                    <Item dataField="id" caption="Id" visible={false} />
                                    <Item dataField="firstName" />
                                    <Item dataField="lastName" />
                                    <Item dataField="mothersLastName" />
                                    <Item dataField="email" />
                                    <Item dataField="phone" />
                                </Item>

                                <Item itemType="group" caption=" " colCount={2} colSpan={2}>
                                    <div className="dx-field-label">Sucursales:</div>
                                    <div className="dx-field-value">
                                        <TagBox
                                            id="sucursales"
                                            onContentReady={setBranchOfficesSelected}
                                            // onValueChanged={onValueChanged}
                                            dataSource={dataBranchOffices}
                                            inputAttr={productLabel}
                                            valueExpr="id"
                                            searchEnabled={true}
                                            grouped={false}
                                            displayExpr="text"
                                            placeholder="Sucursales ..." />
                                    </div>
                                </Item>
                            </Form>

                        </Editing>

                        <Grouping autoExpandAll={false} />

                        <Column dataField="id" visible={false} ></Column>

                        <Column dataField="firstName" caption="Nombre"></Column>

                        <Column dataField="lastName" caption="Apellido Paterno"></Column>

                        <Column dataField="mothersLastName" caption="Apellido Materno"></Column>

                        <Column dataField="email" caption="Email"></Column>

                        <Column dataField="phone" caption="Teléfono"></Column>

                        <GroupItem summaryType="count" />
                    </DataGrid>
                </div>
            );
        }

        else {

            return <h1> Acceso denegado</h1>

        }

    }
}

function showToast(event, value, type) {
    notify(`${event} "${value}" task`, type, 2000);
    // showToast('Se actualizo exitosamente la cita de', e.oldData.text, 'info');
}

function onPopupFormOpening(e) {
    podologoInfo = e;
    var _listBranchOffices = podologoInfo.data.branchOfficeDoctors;

    var selectedBranchOffices = _listBranchOffices.map(function (item) {
        return item['id'];
    });

    defaultValues.grouped = selectedBranchOffices;
}

function setBranchOfficesSelected(e) {
    _tagBox = e;
    _tagBox.component.option("value", defaultValues.grouped);
}

function cleanTagbox() {
    defaultValues.grouped = []
    _tagBox.component.option("value", defaultValues.grouped);
}

// function onValueChanged(e) {
//     podologoInfo.data.branchOfficeId = _tagBox.component.option("value");
// }

function savingMethod(e) {


    if (_tagBox != undefined || _tagBox != null) {
        var value = _tagBox.component.option("value");

        if (e.changes.length == 0) {
            var podologoToUpdate = {
                data: { 'branchOfficeId': value },
                key: podologoInfo.data.id,
                type: "update"
            }
            e.changes.push(podologoToUpdate);
        }
    }
    else {

    }
}


export default withAuth0(DoctorList);
