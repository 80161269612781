import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import CalendarDefault from 'pages/extra-pages/calendar';
import RegisterAppoinment from 'components/FormsApp/RegisterAppoinment';
import AppointmentForm from 'components/FormsApp/AppointmentForm';
import AgendaPDS from 'components/AgendaPDS';
import AgendaPDSVinedos from 'components/AgendaPDSVinedos';
import DoctorList from 'components/DoctorList';
import AgendaPDSNavarro from 'components/AgendaPDSNavarro';
import AgendaPDSConstitucion from 'components/AgendaPDSConstitucion';
import AgendaPDSRevolucion from 'components/AgendaPDSRevolucion';
import ReportsMenu from 'components/AppointmentList';


// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));

// render - utilities
const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));
// ==============================|| MAIN ROUTING ||============================== //



let MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'color',
            element: <Color />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        },
        {
            path: 'calendar',
            element: <CalendarDefault />
        },
        {
            path: 'shadow',
            element: <Shadow />
        },
        {
            path: 'typography',
            element: <Typography />
        },
        {
            path: 'icons/ant',
            element: <AntIcons />
        },
        {
            path: 'agenda',
            element: <AgendaPDS />
        },
        {
            path: 'agendaVinedos',
            element: <AgendaPDSVinedos />
        },
        {
            path: 'agendaNavarro',
            element: <AgendaPDSNavarro />
        },
        {
            path: 'agendaConstitucion',
            element: <AgendaPDSConstitucion />
        },
        {
            path: 'agendaRevolucion',
            element: <AgendaPDSRevolucion />
        },
        {
            path: 'registerappointment',
            element: <RegisterAppoinment />
        },
        {
            path: 'appointmentform',
            element: <AppointmentForm />
        },
        {
            path: 'appointmentList',
            element: <ReportsMenu />
        }
        ,
        {
            path: 'doctorList',
            element: <DoctorList />
        }
    ]
};

export default MainRoutes;
